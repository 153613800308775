var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},on:{"input":_vm.valueChange},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"gold"},[_c('v-card-title',[_vm._v("อัปโหลดสลิปฝากเงิน")]),_c('v-card-text',{staticClass:"pa-5"},[_c('v-form',{ref:"form",attrs:{"disabled":_vm.isLoading},on:{"submit":function($event){$event.preventDefault();}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.upload.apply(null, arguments)}}},[_c('date-picker',{attrs:{"type":"datetime","locale":"th","format":"YYYY-MM-DD HH:mm:00","min":_vm.minDate,"max":_vm.maxDate,"custom-input":"#input","show":_vm.datepickerShow,"editable":true,"auto-submit":""},on:{"close":function($event){_vm.datepickerShow = false}},scopedSlots:_vm._u([{key:"submit-btn",fn:function(ref){
var canSubmit = ref.canSubmit;
var color = ref.color;
var submit = ref.submit;
return [_c('button',{style:({ color: color }),attrs:{"type":"button","disabled":!canSubmit},domProps:{"textContent":_vm._s('เลือกวันเวลา')},on:{"click":submit}})]}},{key:"cancel-btn",fn:function(ref){
var color = ref.color;
return [_c('button',{style:({ color: color }),attrs:{"type":"button"},domProps:{"textContent":_vm._s('ยกเลิก')},on:{"click":function($event){_vm.vm.visible = false}}})]}},{key:"header-year",fn:function(ref){
var selectedDate = ref.selectedDate;
return [_vm._v(" พ.ศ. "+_vm._s(selectedDate.xYear() + 543)+" ")]}},{key:"year-item",fn:function(ref){
var year = ref.year;
return [_vm._v(" "+_vm._s(year.year() + 543)+" ")]}}]),model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('div',{on:{"click":function($event){_vm.datepickerShow = true}}},[_c('s-label-text',{attrs:{"value":_vm.showDate,"label_text":_vm.$t('deposit_inform.date'),"error-messages":_vm.errors.slip_date,"rules":_vm.rules.slip_date,"type":"text","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"gray"}},[_vm._v("mdi-calendar")])]},proxy:true}])})],1),_c('s-label-text',{attrs:{"error-messages":_vm.errors.slip || _vm.slip.isError,"label_text":_vm.$t('deposit.slip_bank'),"dark":"","outlined":""}},[_c('v-file-input',{staticClass:"pt-0",attrs:{"outlined":false,"rules":_vm.rules.image,"accept":"image/png, image/jpeg, image/bmp","dense":"","hide-details":"","show-size":"","dark":""},on:{"change":_vm.check_file,"update:error":_vm.test_error},model:{value:(_vm.slip.file),callback:function ($$v) {_vm.$set(_vm.slip, "file", $$v)},expression:"slip.file"}})],1),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"font-size":"0.8em"}},[_vm._v(" ประเภทไฟล์: jpg, png ขนาดสูงสุด: 3MB ")]),(!_vm.slip.isError)?_c('v-img',{staticClass:"my-2",attrs:{"src":_vm.slip.base64,"aspect-ratio":"1.4","contain":""}}):_vm._e(),_c('div',{staticClass:"d-flex justify-end mt-5"},[_c('v-btn',{staticClass:"gold",staticStyle:{"width":"100%"},attrs:{"loading":_vm.isLoading},on:{"click":_vm.upload}},[_vm._v("อัปโหลด")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }