var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"disabled":_vm.isLoading},on:{"submit":function($event){$event.preventDefault();}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('label-text',_vm._b({attrs:{"disabled":_vm.isLoading,"error-messages":_vm.errors.amount,"label_text":_vm.$t('deposit.amount'),"rules":_vm.rules.amount,"min":"0","type":"number"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-sheet',_vm._b({},'v-sheet',_vm.attr_circle,false),[_vm._v("1")])]},proxy:true}]),model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}},'label-text',_vm.attr_input,false)),_c('date-picker',{attrs:{"type":"datetime","locale":"th","format":"YYYY-MM-DD HH:mm:00","min":_vm.minDate,"max":_vm.maxDate,"custom-input":"#input","show":_vm.datepickerShow,"editable":true,"auto-submit":""},on:{"close":function($event){_vm.datepickerShow = false}},scopedSlots:_vm._u([{key:"submit-btn",fn:function(ref){
var canSubmit = ref.canSubmit;
var color = ref.color;
var submit = ref.submit;
return [_c('button',{style:({ color: color }),attrs:{"type":"button","disabled":!canSubmit},domProps:{"textContent":_vm._s('เลือกวันเวลา')},on:{"click":submit}})]}},{key:"cancel-btn",fn:function(ref){
var color = ref.color;
return [_c('button',{style:({ color: color }),attrs:{"type":"button"},domProps:{"textContent":_vm._s('ยกเลิก')},on:{"click":function($event){_vm.vm.visible = false}}})]}},{key:"header-year",fn:function(ref){
var selectedDate = ref.selectedDate;
return [_vm._v(" พ.ศ. "+_vm._s(selectedDate.xYear() + 543)+" ")]}},{key:"year-item",fn:function(ref){
var year = ref.year;
return [_vm._v(" "+_vm._s(year.year() + 543)+" ")]}}]),model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('div',{on:{"click":function($event){_vm.datepickerShow = true}}},[_c('s-label-text',{attrs:{"value":_vm.showDate,"label_text":_vm.$t('deposit_inform.date'),"error-messages":_vm.errors.slip_date,"rules":_vm.rules.slip_date,"type":"text","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"gray"}},[_vm._v("mdi-calendar")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-sheet',_vm._b({staticClass:"ml-2"},'v-sheet',_vm.attr_circle,false),[_vm._v("2")])]},proxy:true}])})],1),_c('s-label-text',{attrs:{"label_text":_vm.$t('deposit.slip_bank'),"error-messages":_vm.errors.slip}},[_c('v-file-input',{staticClass:"pt-0",attrs:{"disabled":_vm.isLoading,"outlined":false,"rules":_vm.rules.image,"accept":"image/png, image/jpeg, image/bmp","dense":"","hide-details":"","show-size":""},on:{"change":_vm.check_file,"update:error":_vm.test_error},model:{value:(_vm.slip.file),callback:function ($$v) {_vm.$set(_vm.slip, "file", $$v)},expression:"slip.file"}})],1),_c('p',{staticStyle:{"font-size":"0.8em","text-align":"right"}},[_vm._v(" ประเภทไฟล์: jpg, png ขนาดสูงสุด: 3MB ")]),(!_vm.slip.isError)?_c('v-img',{attrs:{"src":_vm.slip.base64,"aspect-ratio":"1.4","contain":""}}):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"btnSubmit mt-0 mr-2 flex-grow-1",attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("deposit.notification")))]),_c('v-sheet',_vm._b({},'v-sheet',_vm.attr_circle,false),[_vm._v("3")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }