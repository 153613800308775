<template>
  <v-dialog v-model="dialog" width="500" @input="valueChange">
    <v-card class="gold">
      <v-card-title>อัปโหลดสลิปฝากเงิน</v-card-title>
      <v-card-text class="pa-5">
        <v-form
          ref="form"
          :disabled="isLoading"
          @keyup.native.enter="upload"
          @submit.prevent=""
        >

          <date-picker
            type="datetime"
            locale="th"
            format="YYYY-MM-DD HH:mm:00"
            :min="minDate"
            :max="maxDate"
            custom-input="#input"
            :show="datepickerShow"
            v-model="form.date"
            :editable="true"
            @close="datepickerShow = false"
            auto-submit
          >
            <template #submit-btn="{ canSubmit, color, submit }">
              <button
                type="button"
                :disabled="!canSubmit"
                :style="{ color }"
                @click="submit"
                v-text="'เลือกวันเวลา'"
              />
            </template>
            <template #cancel-btn="{ color }">
              <button
                type="button"
                :style="{ color }"
                @click="vm.visible = false"
                v-text="'ยกเลิก'"
              />
            </template>
            <template v-slot:header-year="{ selectedDate }">
              พ.ศ. {{ selectedDate.xYear() + 543 }}
            </template>
            <template v-slot:year-item="{ year }">
              {{ year.year() + 543 }}
            </template>
          </date-picker>

          <div @click="datepickerShow = true">
            <s-label-text
              :value="showDate"
              :label_text="$t('deposit_inform.date')"
              :error-messages="errors.slip_date"
              :rules="rules.slip_date"
              type="text"
              readonly
            >
              <template v-slot:append>
                <v-icon color="gray">mdi-calendar</v-icon>
              </template>
            </s-label-text>
          </div>

          <s-label-text
            :error-messages="errors.slip || slip.isError"
            :label_text="$t('deposit.slip_bank')"
            dark
            outlined
          >
            <v-file-input
              v-model="slip.file"
              :outlined="false"
              :rules="rules.image"
              accept="image/png, image/jpeg, image/bmp"
              class="pt-0"
              dense
              hide-details
              show-size
              dark
              @change="check_file"
              @update:error="test_error"
            ></v-file-input>
          </s-label-text>

          <div style="font-size: 0.8em" class="d-flex justify-end">
            ประเภทไฟล์: jpg, png ขนาดสูงสุด: 3MB
          </div>

          <v-img
            v-if="!slip.isError"
            :src="slip.base64"
            aspect-ratio="1.4"
            contain
            class="my-2"
          />

          <div class="d-flex justify-end mt-5">
            <v-btn
              :loading="isLoading"
              class="gold"
              @click="upload"
              style="width: 100%"
              >อัปโหลด</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import validate from "../../helper/validates";
import { mapActions } from "vuex";
export default {
  props: {
    value: {
      type: [Number, Boolean],
      default: 0,
    },
    deposit_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      datepickerShow: false,
      intervalRef: false,
      now: new Date(),
      slip: {
        file: null,
        base64: "",
        isError: true,
      },
      form: {
        date: "",
      },
      rules: {
        amount: [validate.required(), validate.integer(1)],
        deposit_date: [validate.required()],
        image: [
          validate.required(),
          (value) =>
            !value ||
            value.size < 3000000 ||
            "สลิปฝากเงินควรมีขนาดไม่เกิน 3 MB!",
        ],
      },
      errors: {},
    };
  },
  created() {},
  mounted() {
    this.intervalRef = setInterval(() => {
      const now = new Date();
      if (this.now.getMinutes() !== now.getMinutes()) this.now = now;
    }, 1000);
  },
  unmounted() {
    this.intervalRef && clearInterval(this.intervalRef);
  },
  methods: {
    ...mapActions("deposit", ["uploadSlip"]),
    valueChange(value) {
      this.$emit("input", value);
    },
    check_file(file) {
      var reader = new FileReader();
      reader.onloadend = () => {
        this.$set(this.slip, "base64", reader.result);
      };
      reader.readAsDataURL(file);
    },
    test_error(error) {
      this.$set(this.slip, "isError", error);
    },
    reset() {
      this.$set(this, "slip", {
        file: null,
        base64: "",
        isError: true,
      });
      this.$set(this.form, "date", "");
      this.$set(this, "errors", {});
    },
    async upload() {
      if (!this.$refs.form.validate() || this.slip.isError) return;
      this.isLoading = true;
      await this.uploadSlip({
        deposit_id: this.deposit_id,
        date: this.form.date,
        slip: this.slip.base64,
      }).then((data) => {
        if (data.error) return;
        this.isLoading = false;
        this.dialog = false;
        this.$emit("input", false);
        this.reset();
      });
      this.isLoading = false;
    },
  },
  computed: {
    showDate() {
      if (this.form.date) {
        const date = new Date(Date.parse(this.form.date));
        ///date.setYear(this.form.date.getFullYear() + 543)
        return date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      return "";
    },
    minDate() {
      const date = new Date(this.now);
      date.setHours(0);
      date.setMinutes(0);
      date.setDate(date.getDate() - 1);
      return `${date.toISOString().replace(/T/g, " ").slice(0, 10)}  00:00`;
    },
    maxDate() {
      const date = new Date(this.now).toISOString().replace(/T/g, " ").slice(0, 8)
      const day = new Date(this.now).toLocaleString('th-TH', { day: '2-digit' })

      const time = new Date(this.now).toLocaleString('th-TH',{
        hour: '2-digit',
        minute: '2-digit',
      })
      return `${date}${day} ${time}`;
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.reset();
        this.$refs.form.resetValidation();
      }
      this.dialog = val;
    },
  },
};
</script>

<style>
</style>
