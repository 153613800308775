<template>
<form-history-layout
  :type="`deposit${ depositType === 'problem' ? '_problem' : '' }`"
  :items="list"
>
  <template v-slot:formLayout>
    <div v-if="!deposit_open" class="text-center">
      <v-img src="/images/logo_ak55bet.png" />

      <h2 v-if="!deposit_open">ระบบฝากเงินปิดปรับปรุงชั่วคราว</h2>
    </div>
    <inform v-else-if="depositType === 'inform'" />
    <fast v-else-if="!waiting && deposit_open" />
    <div v-else-if="waiting" :set="countTime = countdownTime()">
      <h1 class="text-center">จำนวนเงินที่ต้องโอน</h1>
      <div class="d-flex justify-center align-center">
        <h1 class="mx-2">{{ waiting.amount }}</h1>
        <icon-copy-text :text="waiting.amount"/>
      </div>
      <div class="mt-5">ธนาคารที่ต้องโอน</div>
      <v-card :class="[waiting.bank_name, 'bank', 'mx-5']">
        <v-card-text>
          <div class="d-flex justify-end align-center">
            <div class="white--text mx-2">{{ $t(`bank.${waiting.bank_name}`) }}</div>
            <img :src="`/images/banks/${waiting.bank_name}.png`" widht="25" height="25" />
          </div>
          <div class="d-flex justify-center align-center">
            <h2 class="my-5 white--text mx-2 text-center">{{waiting.number}}</h2>
            <icon-copy-text :text="waiting.number"/>
          </div>

          <div class="mt-2 white--text text-center">
            {{waiting.name}}
          </div>
        </v-card-text>
      </v-card>
      <div class="my-2 mx-5 d-flex align-center">
        <div class="d-flex justify-center flex-grow-1">
          <countdown v-if="countTime" :time="countTime" @progress="handleCountdownProgress">
            <template slot-scope="props">กรุณาทำรายการภายใน {{ props.minutes }} นาที {{ props.seconds }} วินาที.</template>
          </countdown>
          <div v-else> ระบบกำลังทำการยกเลิกอัตโนมัติ</div>
        </div>
        <v-btn v-if="waiting.type === 'cdm'" @click="cancelCDM()" :loading="isLoading" class="mt-0" color="red" dark>{{ $t("common.cancel") }}</v-btn>
        <v-btn v-else class="mt-0" @click="cancel(waiting.id)" :loading="isLoading" :disabled="isLoading || disableCancel" color="red" dark>{{ $t("common.cancel") }}</v-btn>
      </div>

      <div class="mt-5">ธนาคารที่ใช้โอน</div>
      <v-card :class="[profile.bank_name, 'bank', 'mx-5']">
        <v-card-text>
          <div class="d-flex justify-end align-center">
            <div class="white--text mx-2">{{ $t(`bank.${profile.bank_name}`) }}</div>
            <img :src="`/images/banks/${profile.bank_name}.png`" widht="25" height="25" />
          </div>
          <h2 class="my-5 white--text mx-2 text-center">{{profile.bank_number}}</h2>
          <div class="mt-2 white--text text-center">
            {{profile.bank_user}}
          </div>
        </v-card-text>
      </v-card>

    </div>
  </template>

  <template v-slot:append-table>
    <dialog-slip
      v-model="dialog"
      :deposit_id="deposit_id"
    />
  </template>

  <template v-slot:[`item.comment`]="{ item }">
    {{ item.comment }} {{ item.description }}
  </template>

  <template v-slot:[`item.status`]="{ item }">
    <v-btn v-if="item.can_upload" @click="deposit_id=item.id; dialog=true;" text>แนบสลิป</v-btn>
    <span v-else-if="item.complete" class="green--text">{{ $t('deposit.success') }}</span>
    <span v-else-if="!item.active" class="red--text">{{ $t('deposit.cancel') }}</span>
    <span v-else class="orange--text">{{ $t('deposit.waiting') }}</span>
  </template>

</form-history-layout>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import FormHistoryLayout from '../../layouts/sub_layouts/FormHistoryLayout.vue'
import DialogSlip from '../../components/deposit/DialogSlip.vue'
import IconCopyText from '../../components/common/IconCopyText.vue'
import Inform from './deposit/inform.vue'
import Fast from './deposit/fast.vue'
export default {
  components: {
    FormHistoryLayout,
    IconCopyText,
    DialogSlip,
    Inform,
    Fast
  },
  data() {
    return {
      isLoading: false,
      depositType: 'standard',
      disableCancel: true,
      dialog: false,
      deposit_id: 0
    }
  },
  created() {},
  mounted() {
    this.depositType = this.$route.meta.type || 'inform'
    window.addEventListener('focus', this.windowFocus)
  },
  async beforeUnmount() {
    window.removeEventListener('focus', this.windowFocus)
  },
  methods: {
    ...mapActions('app', ['page_loading']),
    ...mapActions('deposit', {'cancelDeposit': 'cancel', 'cancelDepositCDM': 'cancelCDM'}),
    windowFocus() {
      if (this.waiting && this.countdownTime() === 0) this.disableCancel = true
    },
    countdownTime() {
      const created = new Date(Date.parse(this.waiting.created_at))
      created.setMinutes(created.getMinutes() + 10)
      const countTime = (created - new Date())
      if (countTime >= 1) return countTime
      return 0
    },
    handleCountdownProgress(data) {
      if (data.totalMinutes <= 1) {
        this.disableCancel = true
      }
      else this.disableCancel = false
    },
    async cancel(id) {
      this.isLoading = true
      await this.cancelDeposit(id)
      this.isLoading = false
    },
    async cancelCDM() {
      this.isLoading = true
      await this.cancelDepositCDM(this.waiting.id)
      this.isLoading = false
    }
  },
  computed: {
    ...mapGetters('deposit', ['deposits', 'depositQueues']),
    ...mapGetters('app', ['deposit_open']),
    ...mapGetters('member', ['profile']),
    waiting() {
      if (this.profile.cdm_approve === 0) {
        return {
          type: 'cdm',
          bank_name: this.profile.cdm_bank_name,
          number: this.profile.cdm_bank_number,
          name: this.profile.cdm_bank_user + ' ' +'cdm',
          amount: this.profile.cdm_decimal,
        }
      }

      if (this.deposits.length === 0) return false
      else {
        const waiting = this.deposits.find(d => ['ฝากด่วน', 'cdm'].includes(d.type) && d.active && d.complete === 0)
        if (waiting) return waiting
        else return false
      }
    },
    list() {
      return [...this.depositQueues, ...this.deposits].sort((a, b) => {
        const aUpdate = a.id
        const bUpdate = b.id

        if (aUpdate > bUpdate) return -1
        else if (aUpdate < bUpdate) return 1
        else return 0
      }).slice(0, 10)
    }
  },
  watch: {
    "$route.meta.type"(val) {
      this.depositType = val
    }
  }
}
</script>
