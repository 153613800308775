<template>
  <v-form
    ref="form"
    :disabled="isLoading"
    @keyup.native.enter.prevent="submit"
    @submit.prevent=""
  >
    <label-text
      v-model="form.amount"
      v-bind="attr_input"
      :disabled="isLoading"
      :error-messages="errors.amount"
      :label_text="$t('deposit.amount')"
      :rules="rules.amount"
      min="0"
      type="number"
    >
      <template v-slot:append-outer>
        <v-sheet v-bind="attr_circle">1</v-sheet>
      </template>
    </label-text>

    <date-picker
      type="datetime"
      locale="th"
      format="YYYY-MM-DD HH:mm:00"
      :min="minDate"
      :max="maxDate"
      custom-input="#input"
      :show="datepickerShow"
      v-model="form.date"
      :editable="true"
      @close="datepickerShow = false"
      auto-submit
    >
      <template #submit-btn="{ canSubmit, color, submit }">
      <button
        type="button"
        :disabled="!canSubmit"
        :style="{ color }"
        @click="submit"
        v-text="'เลือกวันเวลา'"
      />
      </template>
      <template #cancel-btn="{ color }">
        <button
          type="button"
          :style="{ color }"
          @click="vm.visible = false"
          v-text="'ยกเลิก'"
        />
      </template>
      <template v-slot:header-year="{ selectedDate }">
        พ.ศ. {{ selectedDate.xYear() + 543 }}
      </template>
      <template v-slot:year-item="{ year }">
        {{ year.year() + 543 }}
      </template>
    </date-picker>

    <div @click="datepickerShow = true">
      <s-label-text
        :value="showDate"
        :label_text="$t('deposit_inform.date')"
        :error-messages="errors.slip_date"
        :rules="rules.slip_date"
        type="text"
        readonly
      >
        <template v-slot:append>
          <v-icon color="gray">mdi-calendar</v-icon>
        </template>
        <template v-slot:append-outer>
          <v-sheet v-bind="attr_circle" class="ml-2">2</v-sheet>
        </template>
      </s-label-text>
    </div>

    <s-label-text
      :label_text="$t('deposit.slip_bank')"
      :error-messages="errors.slip"
    >
      <v-file-input
        v-model="slip.file"
        :disabled="isLoading"
        :outlined="false"
        :rules="rules.image"
        accept="image/png, image/jpeg, image/bmp"
        class="pt-0"
        dense
        hide-details
        show-size
        @change="check_file"
        @update:error="test_error"
      ></v-file-input>
    </s-label-text>

    <p style="font-size: 0.8em; text-align: right">
      ประเภทไฟล์: jpg, png ขนาดสูงสุด: 3MB
    </p>

    <v-img v-if="!slip.isError" :src="slip.base64" aspect-ratio="1.4" contain />

    <div class="d-flex align-center">
      <v-btn
        :disabled="isLoading"
        :loading="isLoading"
        class="btnSubmit mt-0 mr-2 flex-grow-1"
        color="primary"
        @click="submit"
        >{{ $t("deposit.notification") }}</v-btn
      >
      <v-sheet v-bind="attr_circle">3</v-sheet>
    </div>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

import LabelText from "../../../components/common/LabelText.vue";
import validate from "../../../helper/validates";

// const now = (timeZone = 'Asia/Bangkok') => {
//   const d = new Date().toLocaleString('en-GB', { timeZone }).split(/\/|, | |:/)
//   const dateString = `${d[2]}-${('00' + d[1]).slice(-2)}-${('00' + d[0]).slice(-2)} ${('00' + d[3]).slice(-2)}:${('00' + d[4]).slice(-2)}:${('00' + d[5]).slice(-2)}`
//   return new Date(dateString)
// }

export default {
  components: {
    LabelText,
  },
  data() {
    return {
      isLoading: false,
      intervalRef: false,
      datepickerShow: false,
      now: new Date(),
      ...this.$createReset(this, "form", {
        amount: 0,
        date: "",
      }),
      ...this.$createReset(this, "slip", {
        file: null,
        base64: "",
        isError: true,
      }),
      rules: {
        amount: [validate.required(), validate.integer(100)],
        deposit_date: [
          validate.required(),
          (v) => {
            const date = new Date(v);
            const dateDiff = Math.round(
              (Date.now() - date) / (1000 * 60 * 60 * 24)
            );
            return dateDiff >= 3 ? "สามารถแจ้งฝากล่าช้าได้ไม่เกิน 2 วัน" : true;
          },
          (v) => {
            const date = new Date(v);
            const dateDiff = Math.round((Date.now() - date) / (1000 * 60));
            return dateDiff <= 0
              ? "ไม่สามารถป้อนวันเวลาที่มากกว่าวันเวลาปัจจุบันได้"
              : true;
          },
        ],
        image: [
          validate.required(),
          (value) =>
            !value ||
            value.size < 3000000 ||
            "สลิปฝากเงินควรมีขนาดไม่เกิน 3 MB!",
        ],
      },
      errors: {},
    };
  },
  created() {},
  mounted() {
    this.reset();
    this.intervalRef = setInterval(() => {
      const now = new Date();
      if (this.now.getMinutes() !== now.getMinutes()) this.now = now;
    }, 1000);
  },
  unmounted() {
    this.intervalRef && clearInterval(this.intervalRef);
  },
  methods: {
    ...mapActions("deposit", ["createInform"]),
    check_file(file) {
      var reader = new FileReader();
      reader.onloadend = () => {
        this.$set(this.slip, "base64", reader.result);
      };
      reader.readAsDataURL(file);
    },
    test_error(error) {
      this.$set(this.slip, "isError", error);
    },
    reset() {
      this.$$reset.form();
      this.$$reset.slip();
    },
    async submit() {
      if (this.$refs.form.validate() && !this.slip.isError) {
        this.isLoading = true;

        await this.createInform({
          amount: this.form.amount,
          date: this.form.date,
          slip: this.slip.base64,
        }).then(async (data) => {
          if (data.error) return

          this.$store.dispatch("app/showSuccess", "แจ้งปัญหาการฝากสำเร็จ", {
            root: true,
          });
          this.reset();
          this.$refs.form.resetValidation()
        });
        this.isLoading = false;
      }
    },
  },
  computed: {
    showDate() {
      if (this.form.date) {
        const date = new Date(Date.parse(this.form.date));
        ///date.setYear(this.form.date.getFullYear() + 543)
        return date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      return "";
    },
    minDate() {
      const date = new Date(this.now);
      date.setHours(0);
      date.setMinutes(0);
      date.setDate(date.getDate() - 1);
      return `${date.toISOString().replace(/T/g, " ").slice(0, 10)}  00:00`;
    },
    maxDate() {
      const date = new Date(this.now).toISOString().replace(/T/g, " ").slice(0, 8)
      const day = new Date(this.now).toLocaleString('th-TH', { day: '2-digit' })
      const time = new Date(this.now).toLocaleString('th-TH',{
        hour: '2-digit',
        minute: '2-digit',
      })
      return `${date}${day} ${time}`;
    },
    attr_text: () => ({
      color: "grey",
      outlined: true,
      dense: true,
      dark: false,
    }),
    attr_circle: () => ({
      class: "rounded-circle d-flex justify-center align-center",
      color: "green",
      width: "30px",
      height: "30px",
      dark: true,
    }),
    attr_input: () => ({
      outlined: true,
      dense: true,
      dark: true,
      input: true,
    }),
  },
};
</script>

<style>
.vpd-day {
  color: gray;
}
</style>
