<template>
  <v-form
    ref="form"
    :disabled="isLoading"
    @keyup.native.enter.prevent="submit"
    @submit.prevent=""
  >
    <label-text
      v-model="form.amount"
      v-bind="attr_input"
      :error-messages="errors.amount"
      :label_text="$t('deposit.amount')"
      :rules="rules.amount"
      outlined
      min="0"
      dark
      type="number"
    >
      <template v-slot:append-outer>
        <v-sheet v-bind="attr_circle">1</v-sheet>
      </template>
    </label-text>

    <div class="d-flex align-center">
      <v-btn
        :disabled="isLoading"
        :loading="isLoading"
        class="btnSubmit mt-0 mr-2 flex-grow-1"
        color="primary"
        @click="submit"
        >{{ $t("deposit.text") }}</v-btn
      >
      <v-sheet v-bind="attr_circle">2</v-sheet>
    </div>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LabelText from "../../../components/common/LabelText.vue";
import validate from "../../../helper/validates";

export default {
  components: {
    LabelText,
  },
  data() {
    return {
      isLoading: false,
      form: {
        amount: 0,
      },
      rules: {
        amount: [validate.required(), validate.integer(100)],
      },
      errors: {},
    };
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions("deposit", ["create"]),
    check_file() {
      return true;
    },
    test_error() {
      return true;
    },
    async submit() {
      this.isLoading = true;
      await this.create(this.form.amount).catch(() => {});
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters("data", ["bank"]),
    attr_text: () => ({
      color: "grey",
      outlined: true,
      dense: true,
      dark: false,
    }),
    attr_circle: () => ({
      class: "rounded-circle d-flex justify-center align-center",
      color: "green",
      width: "30px",
      height: "30px",
      dark: true,
    }),
    attr_input: () => ({
      outlined: true,
      dense: true,
      dark: false,
      input: true,
    }),
  },
};
</script>

<style>
</style>
